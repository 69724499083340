const links = document.querySelectorAll('a:not([href^="#"])')
const queries = window.location.search.replace('?', '&')

links.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    const query =
      (event.currentTarget.href.search(/\?/g) > 0 ? '&' : '?') +
      'partner=pb' +
      queries

    const link = event.currentTarget.href + query

    if (event.currentTarget.href) {
      if (event.currentTarget.target === '_blank') {
        window.open(link, '_blank')
      } else {
        window.location.href = link
      }
    }
  })
})
